<template>
  <v-container>
    <v-row>
      <v-col
        class="mb-4"
        cols="6">
          <v-card>
            <v-form
              ref="form"
              @submit.prevent="computeDeckPowerLevel">
              <v-card-title>Your deck</v-card-title>
              <v-card-text>
                <v-text-field
                  type="text"
                  v-model="commander"
                  hint="Commander"
                  label="Commander"
                ></v-text-field>

                <v-text-field
                  v-model="amv"
                  type="text"
                  hint="Average Converted Mana Cost"
                  label="Average Mana Value"
                ></v-text-field>

                <v-textarea
                  v-model="decklist"
                  clearable
                  rows="12"
                  label="Deck List">
                </v-textarea>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  type="submit"
                  text
                  color="primary">
                  Submit
                </v-btn>
                <v-btn
                  @click="resetForm"
                  type="button"
                  text
                  color="primary">
                  reset
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
      </v-col>
      <v-col
        class="mb-4"
        cols="6"
      >
        <v-card
          class="mx-auto"
          tile>
          <v-card-title>Your deck Power level</v-card-title>
          <v-card-text v-if="submitted">

              <div class="text-subtitle-1">Power level {{ powerLevel }}</div>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Draw ({{ drawCount }})</v-list-item-title>
                  <v-list-item-subtitle>
                    <ul>
                      <li v-for="drawCard in drawCards" :key="drawCard">
                        {{ drawCard }}
                      </li>
                    </ul>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item rwo-line>
                <v-list-item-content>
                  <v-list-item-title>Ramp ({{ rampCount }})</v-list-item-title>
                  <v-list-item-subtitle>
                    <ul>
                      <li v-for="rampcard in rampCards" :key="rampcard">
                        {{ rampcard }}
                      </li>
                    </ul>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Interactions ({{ interactionCount }})</v-list-item-title>
                  <v-list-item-subtitle>
                      <ul>
                      <li v-for="interactionCard in interactionCards" :key="interactionCard">
                        {{ interactionCard }}
                      </li>
                    </ul>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
          </v-card-text>

          <v-card-actions>
            Learn more about the formula&nbsp;
            <a href="https://discipleofthevault.com/2020/11/18/my-edh-power-level-formula/" target="_blank">
              here
            </a>.
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',
    data: () => ({
      amv: null,
      commander: null,
      decklist: null,
      powerLevel: null,
      drawCount: 0,
      drawCards: [],
      tutorCount: 0,
      tutorCards: [],
      rampCount: 0,
      rampCards: [],
      interactionCount: 0,
      interactionCards: [],
      submitted: false
    }),
    methods: {
      computeDeckPowerLevel () {
        console.log('compute')
        this.submitted = true

        // D = Draw that either allows you to see 3 cards, or a permanent that gives you repeatable draw
        // Examples: Brainstorm, Howling Mine, Fact or Fiction, Phyrexian Arena
        const draws = [
          // 'academy ruins',
          'ad nauseam',
          'ambition\'s cost',
          'brainstorm',
          'cathartic reunion',
          'castle vantress',
          'cephalid coliseum',
          'chandra, torch of defiance',
          'consecrated sphinx',
          'damnable pact',
          'dark confidant',
          'dreamstone hedron',
          'endless atlas',
          'esper sentinel',
          'etali, primal storm',
          'fact or fiction',
          'gonti, lord of luxury',
          'greater good',
          'guardian project',
          'howling mine',
          'hunter\'s insight',
          'impulse',
          'jace beleren',
          'jace, wielder of mysteries',
          'jeska\'s will',
          'Lim-Dûl\'s Vault',
          'loyal drake',
          'magnifying glass',
          'magus of the wheel',
          'mystic remora',
          'narset, parter of veils',
          'necropotence',
          'notion thief',
          'outpost siege',
          'peer into the abyss',
          'phyrexian arena',
          'ponder',
          'preordain',
          'pull from tomorrow',
          'read the bones',
          'reforge the soul',
          'reverse engineer',
          'risk factor',
          'rishkar\'s expertise',
          'rhystic study',
          'saheeli\'s directive',
          'sensei\'s divining top',
          'skeletal scrying',
          'skullclamp',
          'strategic planning',
          'syphon mind',
          'sylvan library',
          'thirst for knowledge',
          'tidings',
          'timetwister',
          'ugin, the spirit dragon',
          'underworld connections',
          'war room',
          'well of lost dreams',
          'wheel of fortune',
          'wheel of misfortune',
          'windfall',
          'worldly tutor',
          'yawgmoth\'s will']

        const commanderDraws = [
          'thrasios, triton hero',
          'tymna the weaver'
        ]

        // T = Tutors (with CMC 4 or less) that find combo pieces and other win conditions
        // Examples: Vampiric Tutor, Muddle the Mixture, Tribute Mage, Demonic Tutor
        // For the sake of simplicity, if the permanent's tutoring effect plus it’s cmc
        // are equal to or less than 4, then I would count it towards T (Tutors).
        const tutors = [
          'chord of calling',
          'crop rotation',
          'demonic tutor',
          'diabolic intent',
          'diabolic tutor',
          'eladamri\'s call',
          'eldritch evolution',
          'enlightened tutor',
          'entomb',
          'fabricate',
          'faithless looting',
          'fauna shaman',
          'finale of revelation',
          'gamble',
          'green sun\'s zenith',
          'hurl through hell',
          'imperial recruiter',
          'imperial seal',
          'intuition',
          'inventors\' fair',
          'merchant scroll',
          'muddle the mixture',
          'mystical tutor',
          'neoform',
          'praetor\'s grasp',
          'pyre of heroes',
          'ranger-captain of eos',
          'reshape',
          'survival of the fittest',
          'scheming symmetry',
          'solve the equation',
          'spellseeker',
          'tezzeret the seeker',
          'tolaria\'s west',
          'transmute artifact',
          'tribute mage',
          'trinket mage',
          'urza\'s saga',
          'vampiric tutor',
          'whir of invention',
          'wishclaw talisman'
        ]

        // R = Ramp cards with CMC 2 or less
        // Examples: Llanowar Elves, Rampant Growth, Plague Myr. Sol Ring
        const ramps = [
          'ancient tomb',
          'arcane signet',
          'arbor elf',
          'avacyn\'s pilgrim',
          'birds of paradise',
          'bloom tender',
          'boros signet',
          'cabal ritual',
          'carpet of flowers',
          'chrome mox',
          'city of traitors',
          'coldsteel heart',
          'culling the weak',
          'deathrite shaman',
          'dimir signet',
          'dockside extortionist',
          'elves of deep shadow',
          'elvish mystic',
          'elvish spirit guide',
          'etherium sculptor',
          'farseek',
          'fellwar stone',
          'fyndhorn elves',
          'gaea\'s cradle',
          'generator servant',
          'goblin anarchomancer',
          'green sun\'s zenith',
          'grim monolith',
          'ignoble hierarch',
          'izzet signet',
          'jeska\'s will',
          'liquidmetal torque',
          'lion\'s eye diamond',
          'llanowar elves',
          'lotus petal',
          'mana crypt',
          'mana vault',
          'mind stone',
          'mishra\'s workshop',
          'mox amber',
          'mox diamond',
          'mox opal',
          'nature\'s lore',
          'noble hierarch',
          'nykthos, shrine to nyx',
          'orzhov signet',
          'paradise mantle',
          'phyrexian tower',
          'plague myr',
          'priest of titania',
          'prismatic lens',
          'rampant growth',
          'rakdos signet',
          'rite of flame',
          'ruby Medallion',
          'sky diamond',
          'simian spirit guide',
          'skirk prospector',
          'sol ring',
          'springleaf drum',
          'talisman of creativity',
          'talisman of dominance',
          'talisman of indulgence',
          'talisman of progress',
          'thought vessel',
          'three visits',
          'tinder wall',
          'wild growth'
        ]

        // I= Interaction such as counterspells, targeted removal, board wipes, and even stax
        // Examples: Mana Drain, Swords to Plowshares, Damnation, Winter Orb
        const interactions = [
          'abrade',
          'abrupt decay',
          'assassin\'s trophy',
          'aether gale',
          'agent of treachery',
          'all is dust',
          'archmage\'s charm',
          'arcane denial',
          'aven mindcensor',
          'bane of progress',
          'beast within',
          'bedevil',
          'bedlam',
          'blasphemous act',
          'blind obedience',
          'blink of an eye',
          'bojuka bog',
          'butcher of malakir',
          'capsize',
          'cavalier of night',
          'chain of vapor',
          'chain reaction',
          'chandra, torch of defiance',
          'consuming vapors',
          'chandra\'s ignition',
          'chaos warp',
          'collector ouphe',
          'confirm suspicions',
          'counterspell',
          'crackling doom',
          'cranial archive',
          'culling ritual',
          'cyclonic rift',
          'dauthi voidwalker',
          'deafening silence',
          'decree of annihilation',
          'decree of pain',
          'deflecting swat',
          'delay',
          'disallow',
          'dispel',
          'draconic intervention',
          'dragon\'s fire',
          'drannith magistrate',
          'drown in the loch',
          'duplicant',
          'fell the mighty',
          'fierce guardianship',
          'finale of eternity',
          'fire prophecy',
          'flame spill',
          'flusterstorm',
          'force of negation',
          'force of will',
          'gilded drake',
          'grand abolisher',
          'hostage taker',
          'into the roil',
          'go for the throat',
          'heroic intervention',
          'hour of devastation',
          'lazotep plating',
          'lightning bolt',
          'magmaquake',
          'mana drain',
          'magus of the moon',
          'mental misstep',
          'merciless eviction',
          'mogg salvage',
          'mystic reflection',
          'mortify',
          'muddle the mixture',
          'nature\'s claim',
          'new blood',
          'notion thief',
          'oblivion stone',
          'opposition agent',
          'pact of negation',
          'pongify',
          'perplexing test',
          'pyroblast',
          'rapid hybridization',
          'reality shift',
          'red elemental blast',
          'resculpt',
          'return to dust',
          'rolling earthquake',
          'scrabbling claws',
          'shatterskull smashing',
          'silence',
          'smashing success',
          'snap',
          'sorcerous spyglass',
          'soul sear',
          'spell pierce',
          'spell swindle',
          'split flame',
          'steel hellkite',
          'sublime epiphany',
          'thieving skydiver',
          'tragic slip',
          'swan song',
          'swords to plowshares',
          'teferi\'s protection',
          'tempted by the oriq',
          'terastodon',
          'thran foundry',
          'tibalt\'s trickery',
          'ugin, the spirit dragon',
          'unstable obelisk',
          'urabrask the hidden',
          'vandalblast',
          'vedalken shackles',
          'venser, shaper savant',
          'veil of summer',
          'victim of night',
          'winds of rebuke'
          // 'vein drinker'
        ]

        this.drawCount = 0;
        this.drawCards = [];
        this.tutorCount = 0;
        this.tutorCards = [];
        this.rampCount = 0;
        this.rampCards = [];
        this.interactionCount = 0;
        this.interactionCards = [];

console.log('this.decklist', this.decklist)
const decklist = this.decklist.toLowerCase();

      for (const draw of draws) {
        if (decklist.includes(draw)) {
          this.drawCount++
          this.drawCards.push(draw);
        }
      }

      for (const draw of commanderDraws) {
        if (decklist.includes(draw)) {
          this.drawCount = this.drawCount + 2
          this.drawCards.push(draw);
        }
      }


for (const tutor of tutors) {
  if (decklist.includes(tutor)) {
    this.tutorCount++,
    this.tutorCards.push(tutor);
  }
}

for (const ramp of ramps) {
  if (decklist.includes(ramp)) {
    this.rampCount++
    this.rampCards.push(ramp)
  }
}

for (const interaction of interactions) {
  if (decklist.includes(interaction)) {
    this.interactionCount++
    this.interactionCards.push(interaction)
  }
}

console.log('draw count', this.drawCount, this.drawCards.map(drawCard => drawCard.toLowerCase()))
console.log('tutor count', this.tutorCount, this.tutorCards.map(tutorCard => tutorCard.toLowerCase()))
console.log('ramp count', this.rampCount, this.rampCards.map(rampCard => rampCard.toLowerCase()))
console.log('interaction count', this.interactionCount, this.interactionCards.map(interactionCard => interactionCard.toLowerCase()))

const averageManaValue = parseFloat(this.amv)

// console.log('average mana value', averageManaValue)
this.powerLevel = 2 / averageManaValue + (this.drawCount / 2 + this.tutorCount + this.rampCount / 2) / 2 + this.interactionCount / 20
// console.log('Power Level', powerLevel)
      },
      resetForm () {
        this.submitted = false
        this.$refs.form.reset()
      }
    }
  }
</script>
